import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features';
import Footer from './components/Footer';
import ContactUs from './components/ContactUs';

function App() {
  return (
    <Auth0Provider
      domain="dev-sm2tmwdyfa1mft4z.us.auth0.com"
      clientId="2BtKWfo8B7asryY3lBpsE0kjdPUAUt3E"
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
    >
      <Router>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={
              <>
                <Hero />
                <Features />
              </>
            } />
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </Auth0Provider>
  );
}

export default App; 