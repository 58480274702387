import React from 'react';
import styled from 'styled-components';

const ContactSection = styled.section`
  min-height: calc(100vh - 64px);
  padding: 2rem 2rem;
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 1rem 1rem;
    min-height: calc(100vh - 56px);
  }
`;

const Container = styled.div`
  max-width: 800px;
  width: 100%;
  text-align: center;
  margin-top: 4rem;

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 3rem;
  line-height: 1.8;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const ContactCard = styled.div`
  background: white;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  @media (max-width: 768px) {
    padding: 1.5rem;
    flex-direction: column;
    gap: 1.5rem;
  }
`;

const ContactImage = styled.img`
  width: 200px;
  height: auto;
`;

const ContactInfo = styled.div`
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const EmailLink = styled.a`
  color: #0062ff;
  font-size: 1.5rem;
  text-decoration: none;
  display: block;
  margin-top: 1rem;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

function ContactUs() {
  return (
    <ContactSection>
      <Container>
        <Title>Contact Us</Title>
        <Description>
          If you need any help or have any questions,<br />
          please feel free to reach out to us.
        </Description>
        <ContactCard>
          <ContactImage src="/contact-illustration.png" alt="Contact illustration" />
          <ContactInfo>
            <h2>Email us at</h2>
            <EmailLink href="mailto:service@aimeet.cc">
              service@aimeet.cc
            </EmailLink>
          </ContactInfo>
        </ContactCard>
      </Container>
    </ContactSection>
  );
}

export default ContactUs; 